import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { serviceRequestAction } from "../../store/service-request/service-request.action";
import { MESSAGES } from "../../config/default.config";
import { useDispatch } from "react-redux";
import { setIsDirty } from "../../store/seqr-ops/seqr-ops.slice";

function EquipmentModelComponent({
  register,
  setValue,
  setEquipmentModel,
  errorWrap,
  formState,
  clearErrors,

}: {
  register: any;
  setValue: any;
  setEquipmentModel: any;
  errorWrap: any;
  formState: any;
  clearErrors: any;

}) {
  const [states, setStates] = useState<any>([]);
  const errors = formState.errors;
  const dispatch = useDispatch()

  useEffect(() => {
    serviceRequestAction.getAllEquipmentModels().then((response: any) => {
      setStates(response);
    });
  }, []);
  return (
    <>
      <Autocomplete
        options={states ? states : []}
        {...register("equipment_model", {
          required: MESSAGES.EQUIPMENT_MODEL_REQUIRED,
        })}
        onChange={(e: any, data: any) => {
          setEquipmentModel(data);
          setValue("equipment_model", data);
          clearErrors("equipment_model");
          dispatch(setIsDirty(true))
        }}
        getOptionLabel={(option: any) => option}
        renderInput={(params: any) => {
          return <TextField {...params} key={params.id} label="" />;
        }}
      />
      {errorWrap(errors?.equipment_model?.message)}

    </>
  );
}

export default EquipmentModelComponent;


