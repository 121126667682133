import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { MESSAGES } from "../../config/default.config";
import { useDispatch } from "react-redux";
import { setIsDirty } from "../../store/seqr-ops/seqr-ops.slice";

function PriorityComponent({
  register,
  setValue,
  errorWrap,
  formState,
  clearErrors,
}: {
  register: any;
  setValue: any;
  errorWrap: any;
  formState: any;
  clearErrors: any;
}) {
  const states: any = [{ name: "Low" }, { name: "Medium" }, { name: " High" }];
  const errors = formState.errors;
  const dispatch = useDispatch()
  return (
    <>
      <Autocomplete
        {...register("priority", {
          required: MESSAGES.PRIORITY_REQUIRED,
        })}
        options={states}
        onChange={(e: any, data: any) => {
          setValue("priority", data.name);
          clearErrors("priority");
          dispatch(setIsDirty(true))
        }}
        getOptionLabel={(option: any) => option.name}
        renderInput={(params: any) => {
          return <TextField {...params} key={params.id} label="" />;
        }}
      />
      {errorWrap(errors?.priority?.message)}
    </>
  );
}

export default PriorityComponent;
