import React, { useEffect, useState } from "react";
import { serviceRequestAction } from "../store/service-request/service-request.action";
import { Table } from "@mui/material";
import attachment from "../assest/images/attachment-icon.svg";
import sequropsBackIcon from "../assest/images/sequrops_back_icon.svg";
import "./viewMoreOverdue.scss";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { IPaginationPayLoad } from "../store/service-request/service-request.interface";
import PaginatorFile from "../pagination/Paginator";

function ViewMoreOverdue() {
  const [serviceRequesList, setServiceRequestList] = useState<any>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const source = searchParams.get("source");

  const [pagination, setPagination] = useState<any>({
    pageNo: 0,
    pageSize: 10,
  }); 

 //convertin UTC TO IND TIME FORMAT
 const formatLocalDate = (utcDate: string) => {
  const date = new Date(utcDate);
  return date.toLocaleDateString("en-IN");
};

  
  const [totalRecords1, setTotalRecords] = useState(0);

  const [paginationPayload, setPaginationPayload] =
    useState<IPaginationPayLoad>({
      pageNo: pagination.pageNo,
      pageSize: pagination.pageSize,
      sortKey: "",
      sortOrder: "ASC",
    });

    const onPageChange = (event: any) => {
      const { first, rows } = event;
      setPagination((prevState: any) => ({
        ...prevState,
        pageNo: first / rows,
        pageSize: rows,
      }));
    };

  useEffect(() => {
    const response = serviceRequestAction
      .getViewMore(paginationPayload)
      .then((response) => {
        setServiceRequestList(response.OverDueData);
        setTotalRecords(response.totalCount[0].OverDueDataTotalCount);
      });
  }, [paginationPayload]);
  useEffect(() => {
    setPaginationPayload((prevData) => ({
      ...prevData,
      pageNo: pagination.pageNo,
      pageSize: pagination.pageSize,
    }));
  }, [pagination.pageNo, pagination.pageSize]);

  const navigateToUpdateServiceRequest = (id: any) => {
    navigate(`/updateServiceRequest/${id}?source=viewMoreOverdue`);
  };
 
 
  return (
    <div className="container">
      <div className="header d-flex my-5">
        <div className="p-1">
          <a href="/">
            <img src={sequropsBackIcon} alt="seqrops logo" />
          </a>
        </div>
        <div className="px-4 serviceRequestOverduePriorityHeading">
          <h2>Service Request (Overdue) </h2>
        </div>
      </div>
      <div className="seqrops-instance-table-wrapper">
        <div className="seqrops-instance-table">
          <Table className="myTable">
            <thead>
              <tr>
                <th>Order</th>
                <th>Request</th>
                <th>State</th>
                <th>Created Date</th>
                <th>Due Date</th>
                <th>Priority </th>
                <th>Attachment</th>
              </tr>
            </thead>
            <tbody>
              {serviceRequesList?.length > 0 &&
                serviceRequesList?.map((request: any, index: number) => {
                  const currentIndex =
                    index + 1 + pagination.pageNo * pagination.pageSize;

                  return (
                    <>
                      <tr
                        onClick={() => {
                          navigateToUpdateServiceRequest(request.id);
                        }}
                        key={request.id}
                      >
                        <td>{currentIndex}</td>

                        <td>{request.request}</td>
                        <td>
                          {request.state === "Closed" ? (
                            <span className="greenIndicator"></span>
                          ) : request.state === "New" ? (
                            <span className="redIndicator"></span>
                          ) : (
                            <span className="orangeIndicator"></span>
                          )}
                          {/* {request.state} */}
                          {request.state === "Inprogress"
                            ? "In Progress"
                            : request.state}
                        </td>
                        <td>{formatLocalDate(request.created)}</td>
                        <td>{formatLocalDate(request.dueDate)}</td>
                        <td>{request.priority}</td>
                        <td>
                          {request?.attachmentModel?.length > 0 ? (
                            <span className="attachment_icon">
                              <img src={attachment} alt="Attachment Image" />
                            </span>
                          ) : null}
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
      <PaginatorFile
        onPageChange={onPageChange}
        totalRecords={totalRecords1}
        firsts={0}
        rowss={0}
      />
    </div>
  );
}

export default ViewMoreOverdue;
