import React, { useEffect, useState } from "react";
import { Paginator } from "primereact/paginator";
import "./paginator.scss";
interface PaginatorFileProps {
  onPageChange: (event: { first: number; rows: number }) => void;
  totalRecords: number;
  firsts:any;
  rowss:any;

  
}

function PaginatorFile({ onPageChange,totalRecords,firsts,rowss }: PaginatorFileProps) {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    const calculatedPage = Math.floor(first / rows);
    setCurrentPage(calculatedPage);
  }, [firsts, rowss]);

  const handlePageChange = (event: { first: number; rows: number }) => {
    setFirst(event.first);
    setRows(event.rows);
    onPageChange(event);
  };
  return (
    <div className="">
      <Paginator
        first={first}
        rows={rows}
        totalRecords={totalRecords}
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default PaginatorFile;
