import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Button } from "react-bootstrap";
import { useDialog } from "./DialogProvider";

function ConfirmationDialog() {
  const [openDialog, closeDialog] = useDialog();
  const onOpenDialog = ({ handleClose }: { handleClose: () => void }) => {
    openDialog({
      children: (
        <>
          <DialogTitle>
            <b>Unsaved Changes</b>
          </DialogTitle>
          <DialogContent>
            You have unsaved changes. Are you sure you want to continue without
            saving these changes?
          </DialogContent>
          <DialogActions>
            <div className="btn_unsaved">
              <button
                className="cancel-unsaved-changes seqrops-btn btn-outline"
                color="primary"
                onClick={() => {
                  closeDialog();
                }}
                style={{ marginBottom: "5px" }}
              >
                Cancel
              </button>
              <button
                className="seqrops-btn btn-fill"
                color="primary"
                onClick={() => {
                  handleClose();
                }}
                style={{ marginBottom: "5px", marginRight: "0px" }}
              >
                OK
              </button>
            </div>
          </DialogActions>
        </>
      ),
    });
  };

  return {
    onOpenDialog,
    closeDialog,
  };
}

export default ConfirmationDialog;
