import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
// import { serviceRequestAction } from "../../store/service-request/service-request.action";

function StateTableDataComponent({
  register,
  setValue,
  setStatetKey,
}: {
  register: any;
  setValue: any;
  setStatetKey:any;
}) {
    
  const states: any = [{ name: "New" }, { name: "Inprogress" }, { name: "Closed" }];
  return (
    <>
      <Autocomplete
        options={states}
        onChange={(e: any, data: any) => {
          setValue("stateKey", data?.name);
          setStatetKey(data?.name); 
        }}
        getOptionLabel={(option: any) => option.name}
        renderInput={(params: any) => {
          return <TextField {...params} key={params.id} label=""  placeholder="State" />;
        }}
      />
    </>
  );
}

export default StateTableDataComponent;
