import {
  IAddServiceRequestReq,
  ICommentsPayload,
  IServiceRequestDetailsById,
  ITableFilterData,
  IUpdateServiceRequestReq,
  IgetAllInstances,
  IPaginationPayLoad,
  getFilterData,
} from "./service-request.interface";
import { default as request } from "axios";
import { toasterUtils } from "../../utils/toaster.utils";
import { MESSAGES, SESSION_KEY } from "../../config/default.config";
import { serviceRequestService } from "./service-request.service";
import { useNavigate } from "react-router-dom";
import { AppThunk } from "..";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
const addServiceRequest = async (
  IAddServiceRequestReq: IAddServiceRequestReq
) => {
  try {
    const response = await serviceRequestService.addServiceRequest(
      IAddServiceRequestReq
    );
    if (response.success) {
      toasterUtils.showSuccess(MESSAGES.SERVICE_REQUEST_CREATED_SUCCESFULLY);
      return true;
    } else {
      toasterUtils.showError(MESSAGES.SERVICE_REQUEST_CREATION_FAILED);
      return false;
    }
  } catch (error) {
    toasterUtils.showError(MESSAGES.SERVICE_REQUEST_CREATION_FAILED);
    return false;
  }
};
const deleteServiceRequestById = (id: any) => {
  return async () => {
    try {
      const response = await serviceRequestService.deleteServiceRequestById(id);
      if (response) {
        toasterUtils.showSuccess(MESSAGES.SERVICE_REQUEST_DELETED_SUCCESFULLY);
        return true;
      } else {
        toasterUtils.showError(MESSAGES.SERVICE_REQUEST_DELETED_FAILED);
        return false;
      }
    } catch (error) {}
  };
};

const updateServiceRequest = async (
  IUpdateServiceRequestReq: IUpdateServiceRequestReq
) => {
  try {
    const response = await serviceRequestService.updateServiceRequest(
      IUpdateServiceRequestReq
    );
    if (response.success) {
      toasterUtils.showSuccess(MESSAGES.SERVICE_REQUEST_UPDATED_SUCCESFULLY);
      return true;
    } else {
      toasterUtils.showError(MESSAGES.SERVICE_REQUEST_UPDATED_FAILED);
      return false;
    }
  } catch (error) {
    toasterUtils.showError(MESSAGES.SERVICE_REQUEST_UPDATED_FAILED);
    return false;
  }
};
const getAllServiceRequest = async (
  getListAllServiceList: IPaginationPayLoad
) => {
  try {
    const response = await serviceRequestService.getAllServiceRequest(
      getListAllServiceList
    );
    if (response.success) {
      return response?.data;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

const getServiceRequesDetailsById = async (
  id: number
): Promise<IServiceRequestDetailsById | undefined> => {
  try {
    const response = await serviceRequestService.getServiceRequestById(id);
    return response?.data || undefined;
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      toasterUtils.showError(MESSAGES.SOMETHING_WENT_WRONG);
    }
  }
  return undefined;
};

const getPriorityCount = async () => {
  return await serviceRequestService
    .getPriorityCount()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
};
const getOverdueCount = async () => {
  return await serviceRequestService
    .getOverdueCount()
    .then((response) => {
      return response;
    })
    .catch((error) => {});
};

// Pagination
const getAllServiceLists = async (
  getListAllServiceList: IPaginationPayLoad
) => {
  try {
    const response = await serviceRequestService.getAllServiceLists(
      getListAllServiceList
    );
    return response;

    //   if (response.success) {
    //     return response?.data;
    //   } else {
    //     return 0;
    //   }
  } catch (error: any) {
    console.log("errorr in pagination");
    return 0;
  }
};

const fileUpload = async (
  files: any,
  property: any,
  setValue: any,
  setSuccess: (state: boolean) => void
) => {
  try {
    const references = [];
    for (let i = 0; i < files.length; i++) {
      const response = await serviceRequestService.getReference();
      if (response) {
        const reference = response.data.data;
        references.push(reference);
        const formData = new FormData();
        formData.append("file", files[i]);
        formData.append("reference", reference);

        const response1 = await serviceRequestService.fileUpload(formData);

        if (response1) {
          const uploadResponse = response1.data.data.url;
          const updatedPropertyValues = { ...property };
          updatedPropertyValues.attachmentModel.push({
            attachment: uploadResponse,
          });
          setValue("attachmentModel", updatedPropertyValues.attachmentModel);
        } else {
          return 0;
        }
      }
    }
    setSuccess(true);
    return references;
  } catch (error) {
    console.log("Error in getting reference:", error);
    return 0;
  }
};

const getAllEquipmentModels = async () => {
  try {
    const response = await serviceRequestService.getAllEquipmentModels();
    if (response) {
      return response;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

const getAllInstancess = async (getAllInstances: IgetAllInstances) => {
  try {
    const response = await serviceRequestService.getAllInstancess(
      getAllInstances
    );
    if (response) {
      return response;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

const getServiceRequestCommentsById = async (requestId: number) => {
  try {
    const response = await serviceRequestService.getServiceRequestCommentsById(
      requestId
    );
    if (response) {
      return response;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

const getKeyClockBearerToken = async () => {
  try {
    let tokenData = sessionStorageUtils.getLocalStorage(
      SESSION_KEY.LOCAL_STORAGE_KEY
    );
    const response = await serviceRequestService.getKeyClockBearerToken();

    const userListResponse = await serviceRequestService.getKeyClockUserList(
      response?.access_token
    ); // Pass the access token as an argument
    return userListResponse;
  } catch (error) {
    console.log(error);
  }
};

const addComments = async (ICommentsPayload: ICommentsPayload) => {
  try {
    const response = await serviceRequestService.addComments(ICommentsPayload);
    if (response) {
      return response;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

const getCustomers = async () => {
  try {
    const response = await serviceRequestService.getCustomers();
    if (response) {
      return response;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

const getViewMore = async (getViewMoreList: IPaginationPayLoad) => {
  return await serviceRequestService
    .getViewMore(getViewMoreList)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error in taking the view more data", error);
    });
};
const getFilterDatas = async (getFilterData: getFilterData) => {
  try {
    const response = await serviceRequestService.getFilterDatas(getFilterData);
    if (response) {
      return response?.data;
    } else {
      return 0;
    }
  } catch (error: any) {
    console.log("errorr in pagination");
    return 0;
  }
};

const getTableFilterData = async (ItableFilterData: ITableFilterData) => {
  try {
    const response = await serviceRequestService.getTableFilterData(
      ItableFilterData
    );
    if (response) {
      return response;
    } else {
      return 0;
    }
  } catch (error: any) {
    console.log("errorr in pagination");
    return 0;
  }
};

export const serviceRequestAction = {
  addServiceRequest,
  getAllServiceRequest,
  getServiceRequesDetailsById,
  getServiceRequestCommentsById,
  updateServiceRequest,
  getPriorityCount,
  getAllServiceLists,
  addComments,
  fileUpload,
  getAllEquipmentModels,
  getAllInstancess,
  getOverdueCount,
  getKeyClockBearerToken,
  getFilterDatas,
  getCustomers,
  deleteServiceRequestById,
  getViewMore,
  getTableFilterData,
};
