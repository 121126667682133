import React, { useEffect, useState } from "react";
import { Table, Tooltip } from "@mui/material";
import { serviceRequestAction } from "../store/service-request/service-request.action";
import {
  IGetAllServiceRequest,
  IPaginationPayLoad,
  getFilterData,
} from "../store/service-request/service-request.interface";
import "./ServiceRequest_table.scss";
import { useNavigate } from "react-router-dom";
import attachment from "../assest/images/attachment-icon.svg";
import { request } from "http";
import noDatafoundAttachment from "../assest/images/seqrops_no-search-results_icon.svg";
import PaginatorFile from "../pagination/Paginator";

function ServiceRequestTable({
  filteredData,
  filterDatasPayload,
  setTotalRecords,
  paginationPayload,
}: {
  filteredData: any;
  filterDatasPayload: any;
  setTotalRecords: any;
  paginationPayload: any;
}) {
  const [servieRequestList, setServiceRequestList] = useState<any>([]);
  const [hasFilteredResult, setHAsFilteredResult] = useState<boolean>(false);

  const fetchData = (shouldFetchAllData: any) => {
    if (shouldFetchAllData) {
      // Fetch all data based on paginationPayload
      serviceRequestAction
        .getAllServiceRequest(paginationPayload)
        .then((response: any) => {
          const data = response?.data;
          const number = response?.totalRecord;
          setTotalRecords(number);
          setServiceRequestList(data);
        })
        .catch((err: any) => {
          console.log("Error fetching data: ", err);
        });
    } else {
      // Use filtered data
      setServiceRequestList(filteredData);
    }
  };
  useEffect(() => {
    const shouldFetchAllData =
      filteredData?.length === 0 &&
      (filterDatasPayload.sortKey === "" ||
        filterDatasPayload.sortKey === undefined) &&
      (filterDatasPayload.stateKey === "" ||
        filterDatasPayload.stateKey === undefined);

    fetchData(shouldFetchAllData);
  }, [filteredData, filterDatasPayload, paginationPayload]);

  useEffect(() => {
    if (servieRequestList?.length == 0) {
      setHAsFilteredResult(false);
    } else {
      setHAsFilteredResult(true);
    }
  }, [servieRequestList]);
  const navigate = useNavigate();
  const navigateToUpdateServiceRequest = (id: any) => {
    navigate("/updateServiceRequest/" + id);
  };
  const [update, setUpdate] = useState();

  //convertin UTC TO IND TIME FORMAT
  const formatLocalDate = (utcDate: string) => {
    const date = new Date(utcDate);
    return date.toLocaleDateString("en-IN");
  };

  const [pagination, setPagination] = useState<any>({
    pageNo: 0,
    pageSize: 10,
  });

  return (
    <div className="seqrops-instance-table-wrapper">
      <div className="">
        {hasFilteredResult ? (
          <>
            <div className="seqrops-instance-table">
              <Table className="myTable">
                <thead>
                  <tr>
                    <th>Order</th>
                    <th>Request</th>
                    <th>State</th>
                    <th>Created Date</th>
                    <th>Due Date</th>
                    <th>Priority </th>
                    <th>Attachment</th>
                  </tr>
                </thead>
                <tbody>
                  {servieRequestList?.length > 0 &&
                    servieRequestList?.map(
                      (request: IGetAllServiceRequest, index: number) => {
                        const currentIndex =
                          index +
                          1 +
                          paginationPayload.pageNo * paginationPayload.pageSize;
                        
                          const cellClass = request?.priority === "High" ? "redCell" : "";
                        return (
                          <tr
                            onClick={() => {
                              navigateToUpdateServiceRequest(request.id);
                            }}
                            key={request.id}
                          >
                            <td className={cellClass}>{currentIndex}</td>
                            <td className={cellClass}>{request.request}</td>
                            <td className={cellClass}>
                              {request.state === "Closed" ? (
                                <span className="greenIndicator"></span>
                              ) : request.state === "New" ? (
                                <span className="redIndicator"></span>
                              ) : (
                                <span className="orangeIndicator"></span>
                              )}
                              {/* {request.state} */}
                              {request.state === "Inprogress"
                                ? "In Progress"
                                : request.state}
                            </td>
                            <td className={cellClass}>{formatLocalDate(request.created)}</td>
                            <td className={cellClass}>{formatLocalDate(request.dueDate)}</td>
                            <td className={cellClass}>{request?.priority}</td>
                            <td className={cellClass}>
                              {request?.attachment?.length > 0 ? (
                                <span className="attachment_icon">
                                  <img
                                    src={attachment}
                                    alt="Attachment Image"
                                  />
                                </span>
                              ) : null}
                            </td>
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </Table>
            </div>
          </>
        ) : (
          <>
            <div className="noDatafoundAttachment text-centre">
              <img src={noDatafoundAttachment} alt="noDatafoundAttachment" />
              <p> No result found</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ServiceRequestTable;
