import axios from "axios";
import {
  ASSET_MANAGEMENT,
  FILE_SERVICE,
  FSM_API,
  KEYCLOAK_CLIENTS,
  KEYCLOAK_CLIENT_SECRETS,
  KEYCLOAK_GRANT_TYPES,
  token_KEY_CLOCK,
} from "../../config/default.config";
import {
  IAddServiceRequestReq,
  ICommentsPayload,
  IGetAllServiceRequest,
  IProirtyCount,
  ITableFilterData,
  IUpdateServiceRequestReq,
  IPaginationPayLoad,
  IgetAllInstances,
  getFilterData,
} from "./service-request.interface";
import { request } from "http";
import { commonUtils } from "../../utils/common.utils";

const fetchAllServiceRequest = async () => {
  const response = await axios.get(FSM_API.LIST_ALL_FSM);
  return response?.data;
};

const getAllServiceRequest = async (
  getListAllServiceList: IPaginationPayLoad
) => {
  const response = await axios.post(
    FSM_API.LIST_ALL_FSM,
    getListAllServiceList
  );
  return response?.data;
};

const getServiceRequestById = async (id: number) => {
  const response = await axios.get(
    commonUtils.formatString(FSM_API.GET_FSM_BY_ID, id)
  );
  return response?.data;
};

const addServiceRequest = async (
  IAddServiceRequestReq: IAddServiceRequestReq
) => {
  const response = await axios.post(FSM_API.CREATE_FSM, IAddServiceRequestReq);
  return response?.data;
};

const updateServiceRequest = async (
  IUpdateServiceRequestReq: IUpdateServiceRequestReq
) => {
  const response = await axios.put(
    FSM_API.UPDATE_FSM,
    IUpdateServiceRequestReq
  );
  return response?.data;
};

const deleteServiceRequestById = async (id: number) => {
  const respone = await axios.delete(
    commonUtils.formatString(FSM_API.DELETE_FSM + id)
  );
  return respone;
};
const getPriorityCount = async () => {
  const response = await axios.post(FSM_API.GET_PRIORITY_COUNT);
  return response?.data.data;
};
const getOverdueCount = async () => {
  const response = await axios.get(FSM_API.GET_OVERDUE_COUNT);
  return response.data?.data;
};
//pagination api
const getAllServiceLists = async (
  getListAllServiceList: IPaginationPayLoad
) => {
  const response = await axios.post(
    FSM_API.GET_ALL_SERVICELIST,
    getListAllServiceList
  );
  return response;
};

const getReference = async () => {
  const response = await axios.get(FSM_API.GET_FILE_REFERENCE);
  return response;
};
const fileUpload = async (formdata: FormData) => {
  const response = await axios.post(FILE_SERVICE.FILE_UPLOAD, formdata);
  return response;
};

const getAllEquipmentModels = async () => {
  const response = await axios.get(ASSET_MANAGEMENT.GET_ALL_EQUIPMENT_MODELS);
  return response?.data.data;
};

const getAllInstancess = async (getAllInstances: IgetAllInstances) => {
  const response = await axios.post(
    ASSET_MANAGEMENT.GET_ALL_INSTANCE,
    getAllInstances
  );
  return response?.data.data;
};

const getServiceRequestCommentsById = async (requestId: number) => {
  const response = await axios.get(
    FSM_API.GET_SERVICE_COMMENTS_BY_ID + requestId
  );
  return response?.data.data;
};
// const getKeyClockList=async()=>{
//   const response=await axios.post(token_KEY_CLOCK.GET_KEY_CLOCK)
// }

const getKeyClockBearerToken = async () => {
  const data = new URLSearchParams();
  data.append("grant_type", KEYCLOAK_GRANT_TYPES.PASSWORD);
  data.append("client_id", KEYCLOAK_CLIENTS.ASSET_SUITE);
  data.append("client_secret", KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE);
  data.append("password", "hirosh");
  data.append("username", "hirosh");

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  try {
    const response = await axios.post(
      token_KEY_CLOCK.GET_KEY_CLOCK_BEARER_TOKEN,
      data,
      config
    );
    console.log(response);
    return response?.data;
  } catch (error) {
    console.log("Error in fetching KEY CLOCK Access Token in service");
  }
};

const getKeyClockUserList = async (accessToken: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`, // Add the bearer token here
    },
  };

  try {
    const response = await axios.get(token_KEY_CLOCK.GET_KEY_USER_LIST, config);
    return response;
  } catch (error) {
    console.log("Error in fetching KEY CLOCK User List in service");
    console.log(error);
  }
};
const getFilterDatas = async (getFilterData: getFilterData) => {
  const response = await axios.post(FSM_API.GET_FILTER_DATA, getFilterData);
  return response;
};

const addComments = async (ICommentsPayload: ICommentsPayload) => {
  const response = await axios.post(FSM_API.ADD_COMMENTS, ICommentsPayload);
  return response;
};

const getCustomers = async () => {
  const response = await axios.get(FSM_API.GET_CUSTOMERS);
  return response;
};

const getViewMore = async (getViewMoreList: IPaginationPayLoad) => {
  const response = await axios.post(FSM_API.VIEW_MORE, getViewMoreList);
  return response?.data.data;
};

const getTableFilterData = async (ItableFilterData: ITableFilterData) => {
  const response = await axios.post(FSM_API.TableFilterData, ItableFilterData);
  return response?.data;
};

export const serviceRequestService = {
  fetchAllServiceRequest,
  addServiceRequest,
  getAllServiceRequest,
  getServiceRequestCommentsById,
  getServiceRequestById,
  updateServiceRequest,
  getPriorityCount,
  getAllServiceLists,
  getReference,
  addComments,
  fileUpload,
  getAllEquipmentModels,
  getAllInstancess,
  getOverdueCount,
  getKeyClockBearerToken,
  getKeyClockUserList,
  getFilterDatas,
  getCustomers,
  deleteServiceRequestById,
  getViewMore,
  getTableFilterData,
};
