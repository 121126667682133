import { Autocomplete, TextField } from "@mui/material";
function PriorityTableFilterDataComponent({
  register,
  setValue,
  setpriorityKey,
}: {
  register: any;
  setValue: any;
  setpriorityKey: any;
}) {
  const states: any = [{ name: "High" }, { name: "Medium" }, { name: "Low" }];
  return (
    <>
      <Autocomplete
        options={states}
        onChange={(e: any, data: any) => {
          setValue("priorityKey", data?.name);
          setpriorityKey(data?.name);
        }}
        getOptionLabel={(option: any) => option.name}
        renderInput={(params: any) => {
          return (
            <TextField
              {...params}
              key={params.id}
              label=""
              placeholder="Priority"
            />
          );
        }}
      />
    </>
  );
}

export default PriorityTableFilterDataComponent;
