// export const BASE_URL = `${process.env.REACT_APP_FSM_API_URL}`;
// export const ASSET_SUITE_LOGIN_URL = `${process.env.REACT_APP_SUITE_URL}`;

export const BASE_URL = `${process.env.REACT_APP_FSM_API_URL}`;
export const ASSET_SUITE_LOGIN_URL = `${process.env.REACT_APP_SUITE_URL}`;
export const KEYCLOAK_BASE_URL = `${process.env.REACT_APP_KEYCLOAK_API_URL}`;
export const FILE_BASE_URL = `${process.env.REACT_APP_FILE_API_URL}`;
export const DEVICE_BASE_URL = `${process.env.REACT_APP_DEVICE_API_URL}`;
export const AUTH_BASE_URL = `${process.env.REACT_APP_AUTH_API_URL}`;
export const MANAGEMENT_URL = `${process.env.REACT_APP_ASSETS_API_URL}`;
export const CONTRACT_URL = `${process.env.REACT_APP_CONTRACT_URL}`;

export const FSM_API = {
  GET_ALL_SERVICELIST: BASE_URL + "api/v1/serviceRequest/getAllServiceRequest",
  LIST_ALL_FSM: BASE_URL + "api/v1/serviceRequest/listServiceRequest",
  CREATE_FSM: BASE_URL + "api/v1/serviceRequest/createServiceRequest",
  UPDATE_FSM: BASE_URL + "api/v1/serviceRequest/updateServiceRequest",
  GET_FSM_BY_ID: BASE_URL + "api/v1/serviceRequest/listServiceRequestById/{0}",
  GET_PRIORITY_COUNT: BASE_URL + "api/v1/serviceRequest/countOfPriorityHigh",
  GET_FILE_REFERENCE: BASE_URL + "api/v1/serviceRequest/file/reference",
  GET_SERVICE_COMMENTS_BY_ID: BASE_URL + "api/v1/serviceRequest/getComments/",
  GET_OVERDUE_COUNT: BASE_URL + "api/v1/serviceRequest/count0fOverdue",
  GET_FILTER_DATA: BASE_URL + "api/v1/serviceRequest/getFilterData",
  ADD_COMMENTS: BASE_URL + "api/v1/serviceRequest/addComments",
  GET_CUSTOMERS: BASE_URL + "api/v1/customer/get-customers",
  DELETE_FSM: BASE_URL + "api/v1/serviceRequest/delete/",
  VIEW_MORE: BASE_URL + "api/v1/serviceRequest/viewMoreData",
  TableFilterData: BASE_URL + "api/v1/serviceRequest/getTableFilterData",
};
export const FILE_SERVICE = {
  FILE_UPLOAD: FILE_BASE_URL + "api/v1/file-management/upload",
};
export const ASSET_MANAGEMENT = {
  GET_ALL_EQUIPMENT_MODELS:
    MANAGEMENT_URL + "api/v1/instance/get-all-equipment-models",
  GET_ALL_INSTANCE: MANAGEMENT_URL + "api/v1/instance/getAllInstances",
};

export const token_KEY_CLOCK = {
  GET_KEY_CLOCK_BEARER_TOKEN: `https://demo-api.seqrops.in/auth/realms/seqrops/protocol/openid-connect/token`,
  GET_KEY_USER_LIST: `https://demo-api.seqrops.in/auth/admin/realms/seqrops/users`,
};

export const THEME_CHANGE_URL = {
  THEME_CHANGE: "https://demo-api.seqrops.in/oauth/api/v1/attributes/set-theme",
};
export const MESSAGES = {
  SERVICE_REQUEST_CREATE_SUCCESS:
    "Service Request has been successfully created",
  SERVICE_REQUEST_UPDATED_SUCCESS:
    "Service Request has been successfully updated",
  LOGGED_OUT: "Logged out Successfully",
  USER_UNAUTHORIZED: "User unauthorized",
  SOMETHING_WENT_WRONG: "Something went wrong",
  SERVICE_REQUEST_CREATED_SUCCESFULLY: "Service Request created Successfully",
  SERVICE_REQUEST_CREATION_FAILED: "Service Request creation failed",
  SERVICE_REQUEST_UPDATED_SUCCESFULLY: "Service Request updated Successfully",
  SERVICE_REQUEST_UPDATED_FAILED: "Service Request updation failed",
  COMMENTS_REQUIRED: "Comments for the request is necessary",
  CUSTOMER_NAME_REQUIRED: "Please select Customer",
  REQUEST_NAME_REQUIRED: "Please enter Request Name",
  DESCRIPTION_REQUIRED: "Please enter Description",
  DROPDOWN_REQUIRED: "Please select one",
  EQUIPMENT_MODEL_REQUIRED: "Please select Equipment Model",
  EQUIPMENT_REQUIRED: "Please select Equipment",
  ASSIGN_TO_REQUIRED: "Please select Assign To",
  SERVICE_TYPE_REQUIRED: "Please select Service Type",
  PRIORITY_REQUIRED: "Please select Priority",
  FIX_WITHIN_REQUIRED: "Please select Fix Within",
  SERVICE_REQUEST_DELETED_SUCCESFULLY:
    "Service Request has been deleted successfully ",
  SERVICE_REQUEST_DELETED_FAILED: "Service Request  deletion failed ",
};

export const ASSET_URLS = {
  ASSET_SUITE_URL: `${process.env.REACT_APP_SUITE_URL}dashboard`,
  ASSET_MODELS_URL: `${process.env.REACT_APP_MODELS_URL}`,
  ASSET_MANAGEMENT_URL: `${process.env.REACT_APP_ASSETS_URL}`,
  DEVICE_MANAGEMENT_URL: `${process.env.REACT_APP_DEVICE_MANAGEMENT_URL}#/devices`,
  FIELD_SERVICE_MANAGEMENT: `${process.env.REACT_APP_FSM_URL}`,
  OPERATIONAL_DASHBOARD_URL: `${process.env.REACT_APP_DASHBOARD_URL}`,
  MANAGEMENT_DASHBOARD_URL: `${process.env.REACT_APP_DASHBOARD_URL}`,
  CONTRACT_URL: `${process.env.REACT_APP_CONTRACT_URL}/contracts`,
  MONITORING_DASHBOARD_URL: `${process.env.REACT_APP_DASHBOARD_URL}`,
};

export const ASSET_AUTH_API = {
  GET_LOGIN_TOKEN: AUTH_BASE_URL + "api/v1/auth/token",
  VERIFY_LOGIN_TOKEN: AUTH_BASE_URL + "api/v1/auth/token/introspect",
  LOGOUT_TOKEN: AUTH_BASE_URL + "api/v1/auth/logout",
};

export const IFRAME_ID = {
  SUITE_IFRAME_ID: "ifr_suite",
  MODELS_IFRAME_ID: "ifr_models",
  ASSETS_IFRAME_ID: "ifr_assets",
  DEVICE_MANAGEMENET_IFRAME_ID: "ifr_device",
  FSM_IFRAME_ID: "ifr_fsm",
  DASHBOARD_IFRAME_ID: "ifr_dashboard",
};

export const SESSION_TYPE = {
  USER_LOGIN_TYPE: "1",
  USER_LOGOUT_TYPE: "0",
  DOMAIN_PASS_TYPE: "2",
};

export const SESSION_KEY = {
  NAVIGATION_STATE: "vdfwfznVgu",
  LOCAL_STORAGE_KEY: "ut",
  REDIRECT_LOCATION_KEY: "rl",
  CLEAR_ALL_TOKEN_DATA_KEY: "cl",
  SUITE_KEY: "su1te",
  MODELS_KEY: "mod3ls",
  ASSETS_KEY: "ass3ts",
  DEVICE_MANAGEMENET_KEY: "d3vice",
  FSM_KEY: "f2m",
  OPERATIONAL_DASHBOARD_KEY: "0pd",
  MANAGEMENT_DASHBOARD_KEY: "Wgmt",
};

//Asset resource scopes (keycloak)
export const ASSET_SCOPES = [
  "asset-management",
  "asset-models",
  "device-management",
  "asset-suite",
  "field-service-management",
  "operational-dashboard",
  "management-dashboard",
  "asset-contract",
  "monitoring-dashboard",
];
export const ASSET_MANAGEMENT_SCOPE = "asset-management";
export const ASSET_SUITE_SCOPE = "asset-suite";
export const ASSET_MODELS_SCOPE = "asset-models";
export const FIELD_SERVICE_MANAGEMENT_SCOPE = "field-service-management";
export const ADMIN_CONSOLE_SCOPE = "device-management";
export const MANAGEMENT_DASHBOARD = "operational-dashboard";
export const OPERATIONAL_DASHBOARD = "management-dashboard";

export const KEYCLOAK_GRANT_TYPES = {
  PASSWORD: "password",
  CLIENT_CREDENTIALS: "client_credentials",
  REFRESH_TOKENS: "refresh_token",
};

export const COOKIE_DETAILS = {
  COOKIE_NAME: `${process.env.REACT_APP_COOKIE_PREFIX}_user_session_id`,
  COOKIE_DOMAIN: `${process.env.REACT_APP_COOKIE_DOMAIN}`,
};

export const KEYCLOAK_CLIENTS = {
  ASSET_SUITE: "asset-suite",
  ASSET_MANAGEMENT: "asset-management",
  ASSET_MODELS: "asset-models",
  DEVICE_MANAGEMENT: "device-management",
  FIELD_SERVICE_MANAGEMENT: "field-service-management",
  OPERATIONAL_DASHBOARD: "operational-dashboard",
  MANAGEMENT_DASHBOARD: "management-dashboard",
  ASSET_CONTRACT: "asset-contract",
  MONITORING_DASHBOARD: "monitoring-dashboard",
};

export const KEYCLOAK_CLIENT_SECRETS = {
  ASSET_SUITE: `${process.env.REACT_APP_APP_SUITE_CLIENT_SECRET}`,
  ASSET_MANAGEMENT: "",
};

export const DASHBOARD_TYPE = {
  REDIRECT: "redirectFrom",
};
export const CLEAR_CLIENT_SCOPES = "clear-scopes";

export const ASSET_REDIRECT_SCOPES = {
  ASSET_SUITE: "asset_suite",
  ASSET_MANAGEMENT: "asset_management",
  ASSET_MODELS: "asset_models",
  DEVICE_MANAGEMENT: "device_management",
  FIELD_SERVICE_MANAGEMENT: "field_service_management",
  OPERATIONAL_DASHBOARD: "operational_dashboard",
  MANAGEMENT_DASHBOARD: "management_dashboard",
  MONITORING_DASHBOARD: "monitoring_dashboard",
};
