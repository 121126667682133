import { Autocomplete, TextField } from "@mui/material";
import React, { useState } from "react";
import { MESSAGES } from "../../config/default.config";
import { useDispatch } from "react-redux";
import { setIsDirty } from "../../store/seqr-ops/seqr-ops.slice";

function ServiceTypeComponent({
  register,
  setValue,
  errorWrap,
  formState,
  clearErrors,

}: {
  register: any;
  setValue: any;
  errorWrap: any;
  formState: any;
  clearErrors: any;
}) {
  const states: any = [{ name: "Periodic" }, { name: "Emergency" }];
  const errors = formState.errors;
  const dispatch = useDispatch()
  return (
    <>
      <Autocomplete

        options={states}
        {...register("service_type", {
          required: MESSAGES.SERVICE_TYPE_REQUIRED,
        })}
        onChange={(e: any, data: any) => {
          setValue("service_type", data.name)
          clearErrors("service_type");
          dispatch(setIsDirty(true))
        }}
        getOptionLabel={(option: any) => option.name}
        renderInput={(params: any) => {
          return <TextField {...params} key={params.id} label="" />;
        }}
      />
      {errorWrap(errors?.service_type?.message)}

    </>
  );
}

export default ServiceTypeComponent;
