import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { serviceRequestAction } from "../../store/service-request/service-request.action";

function AssignToComponent({
  register,
  setValue,
  setassignToKey,
}: {
  register: any;
  setValue: any;
  setassignToKey: any;
}) {
  const dispatch = useDispatch<any>();

  const [states, setStates] = useState([]);

  useEffect(() => {
    const response = async () => {
      try {
        const data = serviceRequestAction
          .getKeyClockBearerToken()
          .then((data: any) => {
            const usernames = data.data.map((item: any) => item.username);

            setStates(usernames);
          });
      } catch (error) {
        console.log("Error is listing Assign To ::: error =>", error);
      }
    };
    response();
  }, []);

  return (
    <>
      <Autocomplete
        options={states}
        onChange={(e: any, data: any) => {
          setValue("assign_to_name", data);
          setassignToKey(data);
        }}
        getOptionLabel={(option: any) => option}
        renderInput={(params: any) => {
          return (
            <TextField
              {...params}
              key={params.id}
              label=""
              placeholder="Assign"
            />
          );
        }}
      />
    </>
  );
}

export default AssignToComponent;
