import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setIsDirty } from "../../store/seqr-ops/seqr-ops.slice";

function StateComponent({
  register,
  handleTypeChange,
  serviceList,
}: {
  register: any;
  handleTypeChange: (type: string) => void;
  serviceList: any;
}) {
  const states: any = [
    { name: "New" },
    { name: "Inprogress" },
    { name: "Closed" },
  ];
  const [value, setValue] = useState({ name: "New" });
  const dispatch = useDispatch();
  useEffect(() => {
    setValue({ name: serviceList?.state || "" });
  }, [serviceList]);

  return (
    <>
      <Autocomplete
        {...register("state", {})}
        options={states}
        value={value}
        onChange={(e: any, data: any) => {
          handleTypeChange(data?.name);
          dispatch(setIsDirty(true));
        }}
        getOptionLabel={(option: any) =>
          option.name === "Inprogress" ? "In Progress" : option.name
        }
        renderInput={(params: any) => {
          return <TextField {...params} key={params.id} label="" />;
        }}
      />
    </>
  );
}

export default StateComponent;
