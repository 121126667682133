import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISeqrOpsState } from "./seqr-ops.interface";

//initial state
const initialState: ISeqrOpsState = {
  loadingState: {
    loading: false,
  },
  loginState: {
    isLoggedIn: false,
  },
  navigationState: {},
  isDirty: false,
  commentsDirty:false,
  theme: false,
};


const seqrOpsSlice = createSlice({
  name: "seqrOpsSlice",
  initialState: initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loadingState = {
        ...state.loadingState,
        loading: action.payload,
      };
    },
    setLogin: (state, action: PayloadAction<boolean>) => {
      state.loginState = {
        ...state.loginState,
        isLoggedIn: action.payload,
      };
    },
    setIsDirty: (state, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload
    },
    setCommentsDirty: (state, action: PayloadAction<boolean>) => {
      state.commentsDirty = action.payload
    },
    setTheme: (state, action: PayloadAction<boolean>) => {
      state.theme = action.payload
    },
  }
});

export const { setLoading, setLogin,setIsDirty,setCommentsDirty,setTheme} = seqrOpsSlice.actions;
export default seqrOpsSlice.reducer;
