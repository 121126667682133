import React, { useEffect, useState } from "react";
import sequropsBackIcon from "../assest/images/sequrops_back_icon.svg";
import "./serviceRequestListing.scss";
import { useNavigate } from "react-router-dom";
import { serviceRequestAction } from "../store/service-request/service-request.action";
import { Table } from "@mui/material";
import attachment from "../assest/images/attachment-icon.svg";
import {
  ITableFilterData,
  IPaginationPayLoad,
} from "../store/service-request/service-request.interface";
import PriorityTableFilterDataComponent from "./sub-component/priority_component";
import AssignToComponent from "./sub-component/assignTo_component";
import StateTableDataComponent from "./sub-component/state_component";
import { IGetAllServiceRequest } from "../store/service-request/service-request.interface";
import noDatafoundAttachment from "../assest/images/seqrops_no-search-results_icon.svg";
import PaginatorFile from "../pagination/Paginator";

function ServiceRequestListing() {
  const navigate = useNavigate();
  const navigateToServiceRequest = () => {
    navigate("/addServiceRequest");
  };
  const navigateToupdate = useNavigate();
  const navigateToUpdateServiceRequest = (id: any) => {
    navigateToupdate("/updateServiceRequest/" + id);
  };

  const [serviceRequesList, setServiceRequestList] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [register, setRegister] = useState<any>();
  const [value, setValue] = useState<any>();
  const [hasFilteredResult, setHAsFilteredResult] = useState<boolean>(false);
  const [totalRecords1, setTotalRecords] = useState(0);
  const [filterDataTotalCount, setFilterDataTotalCount] = useState(0);

  const [pagination, setPagination] = useState<any>({
    pageNo: 0,
    pageSize: 10,
  });

  const [TableFilterDatasPayload, setTableFilterDatasPayload] =
    useState<ITableFilterData>({
      stateKey: "",
      priorityKey: "",
      sortOrder: "",
      assignToKey: "",
      pageNo: pagination.pageNo,
      pageSize: pagination.pageSize,
    });
  useEffect(() => {
    if (serviceRequesList?.length == 0 || filterDataTotalCount == 0) {
      setHAsFilteredResult(false);
    } else {
      setHAsFilteredResult(true);
    }
  }, [serviceRequesList, TableFilterDatasPayload]);

  const [paginationPayload, setPaginationPayload] =
    useState<IPaginationPayLoad>({
      pageNo: pagination.pageNo,
      pageSize: pagination.pageSize,
      sortKey: "",
      sortOrder: "ASC",
    });
  useEffect(() => {
    setTableFilterDatasPayload((prevState) => ({
      ...prevState,
      pageNo: pagination.pageNo,
      pageSize: pagination.pageSize,
    }));
  }, [pagination.pageNo, pagination.pageSize]);

  useEffect(() => {
    setPaginationPayload((prevData) => ({
      ...prevData,
      pageNo: pagination.pageNo,
      pageSize: pagination.pageSize,
    }));
  }, [pagination.pageNo, pagination.pageSize]);

  useEffect(() => {
    const response = serviceRequestAction
      .getTableFilterData(TableFilterDatasPayload)
      .then((response) => {
        setFilteredData(response?.data?.FilterData);
        setFilterDataTotalCount(
          response?.data?.totalDataCount[0]?.totalDataCount
        );
      });
  }, [TableFilterDatasPayload, paginationPayload]);

  const formatLocalDate = (utcDate: string) => {
    const date = new Date(utcDate);
    return date.toLocaleDateString("en-IN");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (filteredData?.length === 0 || filteredData === undefined) {
          const response = await serviceRequestAction
            .getAllServiceLists(paginationPayload)
            .then((response: any) => {
              const data = response.data.data.data;
              const number = response.data.data.totalRecord;
              setServiceRequestList(data);
              setTotalRecords(number);
            });
        } else {
          setServiceRequestList(filteredData);
          setTotalRecords(filterDataTotalCount);
        }
      } catch (error) {
        console.log("error in loading data: ", error);
      }
    };

    fetchData();
  }, [paginationPayload, TableFilterDatasPayload, filteredData]);

  const onPageChange = (event: any) => {
    const { first, rows } = event;
    setPagination((prevState: any) => ({
      ...prevState,
      pageNo: first / rows,
      pageSize: rows,
    }));
  };

  const navigateToPage = (id: any) => {
    navigateToUpdateServiceRequest(id);
  };

  const renderPriority = () => {
    return (
      <PriorityTableFilterDataComponent
        register={register}
        setValue={setValue}
        setpriorityKey={(priorityKey: string) =>
          setTableFilterDatasPayload(
            (prevTableFilterDatas: ITableFilterData) => ({
              ...prevTableFilterDatas,
              priorityKey: priorityKey === undefined ? "" : priorityKey,
            })
          )
        }
      />
    );
  };
  const renderAssignTo = () => {
    return (
      <AssignToComponent
        register={register}
        setValue={setValue}
        setassignToKey={(assignToKey: string) =>
          setTableFilterDatasPayload(
            (prevTableFilterDatas: ITableFilterData) => ({
              ...prevTableFilterDatas,
              assignToKey: assignToKey === null || undefined ? "" : assignToKey,
            })
          )
        }
      />
    );
  };
  const renderState = () => {
    return (
      <StateTableDataComponent
        register={register}
        setValue={setValue}
        setStatetKey={(stateKey: string) =>
          setTableFilterDatasPayload(
            (prevTableFilterDatas: ITableFilterData) => ({
              ...prevTableFilterDatas,
              stateKey: stateKey === undefined ? "" : stateKey,
            })
          )
        }
      />
    );
  };

  return (
    <>
      <div className="container">
        <div className="header d-flex my-5">
          <div className="p-1">
            <a href="/">
              <img src={sequropsBackIcon} alt="seqrops logo" />
            </a>
          </div>
          <div className="px-4 serviceRequestListingHeading">
            <h2>Service Request</h2>
          </div>
          <div className=" ms-auto">
            <button
              className="btn btn-primary"
              onClick={navigateToServiceRequest}
            >
              Create Request
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="dropDown-bar d-flex my-3 p-2">
          <div className="ms-auto d-flex">
            <div className="d-flex">
              <div className="stateFilterDropDown d-flex">
                {/* <label>State</label> */}
                {renderState()}
              </div>
              <div className="priorityFilterDropDown d-flex">
                {/* <label>Priority</label> */}
                {renderPriority()}
              </div>
              <div className="AssignToFilterDropDown d-flex">
                {/* <label>Assign To</label> */}
                {renderAssignTo()}
              </div>
            </div>
          </div>
        </div>
        <div className="seqrops-instance-table-wrapper">
          <div className="">
            {hasFilteredResult ? (
              <>
                <div className="seqrops-instance-table">
                  <Table className="myTable">
                    <thead>
                      <tr>
                        <th>Order</th>
                        <th>Request</th>
                        <th>State</th>
                        <th>Created Date</th>
                        <th>Due Date</th>
                        <th>Priority </th>
                        <th>Attachment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {serviceRequesList?.length > 0 &&
                        serviceRequesList?.map(
                          (request: IGetAllServiceRequest, index: number) => {
                            const currentIndex =
                              index +
                              1 +
                              pagination.pageNo * pagination.pageSize;
                            return (
                              <>
                                <tr
                                  onClick={() => {
                                    navigateToUpdateServiceRequest(request.id);
                                  }}
                                  key={request.id}
                                >
                                  <td>{currentIndex}</td>

                                  <td>{request.request}</td>
                                  <td>
                                    {request.state === "Closed" ? (
                                      <span className="greenIndicator"></span>
                                    ) : request.state === "New" ? (
                                      <span className="redIndicator"></span>
                                    ) : (
                                      <span className="orangeIndicator"></span>
                                    )}
                                    {/* {request.state} */}
                                    {request.state === "Inprogress"
                                      ? "In Progress"
                                      : request.state}
                                  </td>
                                  <td>{formatLocalDate(request.created)}</td>
                                  <td>{formatLocalDate(request.dueDate)}</td>
                                  <td>{request.priority}</td>
                                  <td>
                                    {request?.attachment?.length > 0 ? (
                                      <span className="attachment_icon">
                                        <img
                                          src={attachment}
                                          alt="Attachment Image"
                                        />
                                      </span>
                                    ) : null}
                                  </td>
                                </tr>
                              </>
                            );
                          },
                          []
                        )}
                    </tbody>
                  </Table>
                </div>
              </>
            ) : (
              <>
                <div className="noDatafoundAttachment text-centre">
                  <img
                    src={noDatafoundAttachment}
                    alt="noDatafoundAttachment"
                  />
                  <p> No result found</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <PaginatorFile
        onPageChange={onPageChange}
        totalRecords={totalRecords1}
        firsts={0}
        rowss={0}
      />
    </>
  );
}

export default ServiceRequestListing;
