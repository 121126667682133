import "./App.scss";
import { Route, Routes } from "react-router-dom";
import Home from "./Main/Home";
import ServiceRequest from "./ServiceRequest/ServiceRequest";
import ErrorPage from "./ErrorPage";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./navbar.component/navbar";
import { ToastContainer } from "react-toastify";
import UpdateServiceRequest from "./components/UpdateServiceRequest/updateServiceRequest";
import ServiceRequestListing from "./serviceRequestListing/serviceRequestListing";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./store";
import {
  ITokenDetails,
  IUserAuthorizations,
  IUserTokenDetails,
  IValidateTokenPayload,
} from "./store/keycloak/asset-keycloak.interface";
import {
  ASSET_SUITE_LOGIN_URL,
  COOKIE_DETAILS,
  IFRAME_ID,
  KEYCLOAK_GRANT_TYPES,
  SESSION_KEY,
  SESSION_TYPE,
} from "./config/default.config";
import { encryptUtils } from "./utils/encryption.utils";
import { validateToken } from "./store/keycloak/asset-keycloak.action";
import { setLogin } from "./store/seqr-ops/seqr-ops.slice";
import ViewMorePriority from "./viewMorePriority/viewMorePriority";
import ViewMoreOverdue from "./viewMoreOverdue/viewMoreOverdue";
import { sessionStorageUtils } from "./utils/session-storage.utils";
import { cookieUtils } from "./utils/cookie.utils";
import DialogProvider from "./dialogs/DialogProvider";
import { setTheme } from "./store/seqr-ops/seqr-ops.slice";
function App() {
  const [ready, setReady] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [loggedUser, setLoggedUser] = useState<string>();
  const theme = useAppSelector((state) => state.seqrOps.theme);
  const [userScopesFromLocalStorage, setUserScopesFromLocalStorage] =
    useState<IUserAuthorizations>();
  const authorozedScopes = useAppSelector((state) => state.assetKeycloak);
  const [localThemeState, setLocalThemeState] = useState<string>("");
  // useEffect(() => {
  //   let cookieName = COOKIE_DETAILS.COOKIE_NAME;
  //   let sessionId = cookieUtils.getCookie(cookieName);
  //   if (sessionId != null && sessionId !== undefined && sessionId !== "") {
  //     dispatch(validateToken(sessionId, setReady));
  //   } else if (sessionId == null || sessionId === undefined || sessionId === "") {
  //     setReady(false);
  //     localStorage.clear();
  //     window.location.replace(ASSET_SUITE_LOGIN_URL);
  //   }
  // }, [])

  useEffect(() => {
    const validateAuth = async () => {
      let cookieName = COOKIE_DETAILS.COOKIE_NAME;
      let encryptedCookieData = cookieUtils.getCookie(cookieName);
      let decryptedCookieData = encryptUtils.decryptURL(encryptedCookieData);
      if (decryptedCookieData?.themeType === "LIGHT") {
        dispatch(setTheme(false));
      } else if (decryptedCookieData?.themeType === "DARK") {
        dispatch(setTheme(true));
      }

      if (
        sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY) !==
        null
      ) {
        let userTokenDetails: IUserTokenDetails =
          sessionStorageUtils.getLocalStorage(
            SESSION_KEY.LOCAL_STORAGE_KEY
          )?.token;
        if (userTokenDetails !== null) {
          setLoggedUser(userTokenDetails?.username);
        }
      }

      if (
        decryptedCookieData?.sessionId != null &&
        decryptedCookieData?.sessionId !== undefined &&
        decryptedCookieData?.sessionId !== ""
      ) {
        const validation = await validateToken(
          decryptedCookieData?.sessionId,
          setReady
        );
        if (!validation) {
          setReady(false);
          localStorage.clear();
          cookieUtils.deleteCookie(cookieName);
          window.location.replace(ASSET_SUITE_LOGIN_URL);
        }
      } else if (
        decryptedCookieData?.sessionId == null ||
        decryptedCookieData?.sessionId === undefined ||
        decryptedCookieData?.sessionId === ""
      ) {
        setReady(false);
        localStorage.clear();
        cookieUtils.deleteCookie(cookieName);
        window.location.replace(ASSET_SUITE_LOGIN_URL);
      }
    };
    //for setting username
    if (
      sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY) !==
      null
    ) {
      let tokenData: any = sessionStorageUtils.getLocalStorage(
        SESSION_KEY.LOCAL_STORAGE_KEY
      );
      if (tokenData !== null) {
        setUserScopesFromLocalStorage(tokenData?.token?.userAuthorizations);
        setLoggedUser(tokenData?.token?.username);
      }
    }
    validateAuth();
  }, []);

  useEffect(() => {
    document.body.id = theme ? "dark" : "light";
  }, [theme]);

  useEffect(() => {
    if (theme) {
      setLocalThemeState("dark");
    } else {
      setLocalThemeState("light");
    }
  }, [theme]);
  if (!ready) {
    return null;
  }

  return (
    <div className="App" id={localThemeState}>
      <div className="dv">
        {/* <Navbar loggeduser={loggedUser !== null ? loggedUser : "Admin"} /> */}
        <Navbar
          loggeduser={
            userScopesFromLocalStorage !== undefined
              ? loggedUser
              : authorozedScopes.username
          }
        />
      </div>
      <DialogProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/addServiceRequest" element={<ServiceRequest />} />
          <Route
            path="/updateServiceRequest/:id"
            element={<UpdateServiceRequest />}
          />
          <Route
            path="/serviceRequestListing"
            element={<ServiceRequestListing />}
          />
          <Route path="/viewMorePriority" element={<ViewMorePriority />} />
          <Route path="/viewMoreOverdue" element={<ViewMoreOverdue />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </DialogProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
