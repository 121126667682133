import { Autocomplete, TextField } from "@mui/material";

function PriorityFilterDataComponent({
  register,
  setValue,
  setSortKey,
}: {
  register: any;
  setValue: any;
  setSortKey:any;
}) {
  const states: any = [{ name: "High" }, { name: "Medium" }, { name: "Low" }];
  return (
    <>
      <Autocomplete
        options={states}
        onChange={(e: any, data: any) => {
          setValue("priority", data?.name);
          setSortKey(data?.name); 
        }}
        getOptionLabel={(option: any) => option.name}
        renderInput={(params: any) => {
          return <TextField {...params} key={params.id} label="" />;
        }}
      />
    </>
  );
}

export default PriorityFilterDataComponent;
