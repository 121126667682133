import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { serviceRequestAction } from "../../store/service-request/service-request.action";
import { MESSAGES } from "../../config/default.config";
import { setIsDirty } from "../../store/seqr-ops/seqr-ops.slice";
import { useDispatch } from "react-redux";

function EquipmentIdComponent({
  register,
  setValue,
  equipmentModel,
  errorWrap,
  formState,
  clearErrors,
}: {
  register: any;
  setValue: any;
  equipmentModel: any;
  errorWrap: any;
  formState: any;
  clearErrors: any;
}) {
  const [states, setStates] = useState<any>([]);
  const errors = formState.errors;
  const dispatch = useDispatch();
  const getAllInstancesss = {
    pageNo: 0,
    pageSize: 5,
    sortKey: "name",
    sortOrder: "asc",
    searchKey: "",
    modelName: equipmentModel,
    getAll: true,
  };
  useEffect(() => {
    serviceRequestAction
      .getAllInstancess(getAllInstancesss)
      .then((response: any) => {
        const instanceName = response.data.map(
          (item: any) => item.instanceName
        );
        setStates(instanceName);
      });
  }, [equipmentModel]);
  return (
    <>
      <Autocomplete
        options={states}
        {...register("instance_name", {
          required: MESSAGES.EQUIPMENT_REQUIRED,
        })}
        onChange={(e: any, data: any) => {
          setValue("instance_name", data);
          clearErrors("instance_name");
          dispatch(setIsDirty(true));
        }}
        getOptionLabel={(option: any) => option}
        renderInput={(params: any) => {
          return <TextField {...params} key={params.id} label="" />;
        }}
      />
      {errorWrap(errors?.instance_name?.message)}
    </>
  );
}

export default EquipmentIdComponent;
